<template>
<!--客户案例头部-->
  <div class="topCusIntroduce">
    <div class="top_show flexrow">
      <div class="show_left wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="show_title white font26rem flextbc color1">
          {{$t(titleStr[index].name) }}
        </div>
        <div class="show_mean white font14rem flextbc">
          {{$t(titleStr[index].detail)}}
        </div>
        <div class="show_contact">
          <div class="btn_contact flexcc blue font10rem fontweight" @click="showPopup">
            {{$t('联系商务合作')}}
          </div>
        </div>
      </div>
      <div class="show_right flexcc wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      </div>
    </div>
    
    <div class="popup" v-show="show==true">
      <ContactPopUp @closePopup="closePopup"></ContactPopUp>
    </div>
  </div>
</template>

<script>
import ContactPopUp from "@/components/ContactPopUp.vue";
export default {
  name: "topCusIntroduce",
  props: ["icon","percent","titleStr","index"],
  data() {
    return {
      show:false
    };
  },
  components: {ContactPopUp},
  created() {
  },
  mounted() {
     new this.$wow.WOW({ live: false }).init();
     // console.log("titleStr", this.titleStr);
    $(".show_right").css("background-image","url("+this.titleStr[this.index].icon+")");
    $(".show_right").css("background-size",this.percent+"%");
  },
  watch: {
    icon(newval, oldval) {
      // console.log("newval", newval);
      // console.log("oldval", oldval);
  },
   titleStr(newval, oldval) {
      // console.log("newval", newval);
      // console.log("oldval", oldval);
      this.titleStr=newval
  },
  },
  methods: {
    showPopup() {
      console.log("oldval");
      this.show = true;
     // $("body").addClass("overflow")
    },
     closePopup(style) {
      //console.log(style, "childByValue父");
      this.show = style;
    },
  },
};
</script>

<style scoped lang="scss">

.popup{
  width: 100%;
  height: 200%;
  position: fixed;
  background-color: rgba(0,0,0,0.2);
  top:-37.5rem;
  z-index: 100;
  padding: 1px;
}
.top_show {
  width: 100%;
  height: 100%;
  background-image: url("../assets/Images/cubg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.top_show .show_left {
  width: 50%;
  height: 100%;
}
.top_show .show_left .show_title {
  width: 80%;
  height: 20%;
  margin-top: 25%;
  padding-left: 3%;
  margin-left: 13%;
  background-image: url("../assets/Images/react.png");
  background-position: 0% center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.top_show .show_left .show_mean {
  width: 80%;
  height: 20%;
  padding-left: 3%;
  margin-left: 13%;
}
.top_show .show_left .show_contact {
  width: 80%;
  height: 20%;
  padding-left: 3%;
  margin-left: 13%;
}
.top_show .show_left .show_contact .btn_contact {
  background-color: #fff;
  border-radius: 8px;
  width: 25%;
  height: 40%;
  cursor: pointer;
}
.top_show .show_right {
  width: 50%;
  height: 100%;
  //background-image: url("../assets/Images/cusbg.png");
  background-position: center 70%;
  background-repeat: no-repeat;
  //background-size: 60%;
}
</style>
